<template>
  <div>
    <c-table
      ref="grid"
      title="관련 작업허가서"
      :columns="grid.columns"
      :data="grid.data"
      :editable="false"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      @linkClick="linkClick"
    >
      <!-- :gridHeight="grid.height" -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 화기작업 등록 -->
          <c-btn v-if="editable" label="LBL0000207" icon="add" @btnClicked="addFire" /> 
          <!-- 일반작업 등록 -->
          <c-btn v-if="editable" label="LBL0000208" icon="add" @btnClicked="addNormal" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'workOrderResultWorkPermit',
  props: {
    param: {
      type: Object,
      default: () => ({
        newEquipmentCd: '',
        newTitle: '',
        newWoWorkTypeCd: '', // 작업유형 (PM)
        workPlanId: '',
        woRequestId: '',
        plantCd: null,
        woWorkPlanStepCd: '',
        workResultId: '',
      }),
    },
    disabledAppr: {
      type: Boolean,
      default: false,
    },
    disabledObj: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: false,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            // 사업장
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: 'LBL0000200',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
          {
            // 작업개요
            fix: true,
            name: 'workSummary',
            field: 'workSummary',
            label: 'LBL0000211',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:250px',
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비유형 / 설비명',
            align: 'left',
            style: 'width:400px',
            sortable: true,
          },
          {
            // 진행단계
            name: 'swpStepName',
            field: 'swpStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: 'LBL0000212',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 허가일
            name: 'permitDate',
            field: 'permitDate',
            label: 'LBL0000213',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 허가서구분
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: 'LBL0000204',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 보충작업
            name: 'supplementWorkTypeNames',
            field: 'supplementWorkTypeNames',
            label: 'LBL0000205',
            align: 'left',
            sortable: true,
            style: 'width:200px',
          },
          {
            // 작업업체
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            sortable: true,
            style: 'width:200px',
          },
          {
            // 신청부서
            name: 'approvalDeptName',
            field: 'approvalDeptName',
            label: 'LBL0000202',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'relationCooperation2DeptName',
            field: 'relationCooperation2DeptName',
            // 승인부서
            label: '승인부서',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
        // height: '320px',
      },
      listUrl: '',
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.workPermit.list.url
      // code setting
      // list setting
      this.getWorkPermitList();
    },
    getWorkPermitList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        workPlanId: this.param.workPlanId
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addFire() {
      // 화기 작업등록
      this.linkClick({ permitTypeCd: 'SPT0000005', })
    },
    addNormal() {
      // 일반 작업등록
      this.linkClick({ permitTypeCd: 'SPT0000001', })
    },
    linkClick(row) {
      // 작업허가서 상세
      this.popupOptions.title = 'LBL0000938';
      this.popupOptions.param = {
        sopWorkPermitId: row ? row.sopWorkPermitId : '',
        permitTypeCd: row ? row.permitTypeCd : '',
        workPlanId: this.param.workPlanId
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getWorkPermitList();
    },
  }
};
</script>